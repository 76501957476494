.App {
    text-align: center;
  }
  
  .front {
    width: 100%;
    height: 400px;
    background-image: url("../public/images/library_hero.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
  }
  .titlefront {
    color: white;
    font-size: 80px;
    margin: auto;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }
  .frontinput {
    width: 400px;
    height: 40px;
    font-size: 25px;
    background-color: #ffffff;
    border: white solid 3px;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
  }

  .frontsubmit {
    width: 150px;
    height: 50px;
    font-size: 25px;
    background-color: #d09100;
    border: white solid 3px;
    color: white;
    margin-left: 30px;
    cursor: pointer;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
  }
  .loader {
    margin: auto;
    width: 80px;
    align-items: center;
    text-align: center;
  }
   svg {
    margin: auto;
    width: 80px;
    align-items: center;
    text-align: center;
  }

  .gap {
    height: 30px;
  }
  .undersearch {
    color: white;
    font-size: 20px;
    margin: auto;
    text-align: center;
    display: inline;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    width: 100%;
    padding-top: 10px;
  }
  .highlight {
    color: #f9ca5f;
  }

  @media only screen and (max-width: 600px) {
    .App {
      text-align: center;
    }
    
    .front {
      width: 100%;
      height: 400px;
      background-image: url("../public/images/library_hero.jpg");
      background-position: center;
      background-size: cover;
      display: flex;
    }
    .titlefront {
      color: white;
      font-size: 70px;
      margin: auto;
      text-align: center;
      font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: black;
    }
    .frontinput {
      width: 300px;
      height: 40px;
      font-size: 25px;
      background-color: #ffffff;
      border: white solid 3px;
      font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    }
  
    .frontsubmit {
      width: 150px;
      height: 50px;
      font-size: 25px;
      background-color: #d09100;
      border: white solid 3px;
      color: white;
      margin-left: 0px;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    }
    .loader {
      margin: auto;
      width: 80px;
      align-items: center;
      text-align: center;
    }
     svg {
      margin: auto;
      width: 80px;
      align-items: center;
      text-align: center;
    }
  
    .gap {
      height: 30px;
    }
    .undersearch {
      color: white;
      font-size: 20px;
      margin: auto;
      text-align: center;
      display: inline;
      font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
      width: 100%;
      padding-top: 10px;
    }
    .highlight {
      color: #f9ca5f;
    }
  }