@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,700&display=swap');
.topbar
{
  width: 100%;
  height: 50px;
  background-color: #313131;
  border-bottom: white solid 1px;
  top: 0;
  left: 0;
  z-index: 1;
  text-align: left;
  font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
  display: flex;
  position: sticky;
    top: 0;
    overflow: hidden;
}
.topbartitle {
  color: white;
  font-size: 30px;
  margin: 0px;
  padding-top: 7px;
  padding-left: 10px;
}
.topbaritem {
  color: white;
  font-size: 25px;
  margin: 0px;
  padding-top: 10px;
  margin-left: 30px;
  cursor: pointer;
}
.topbaritem:hover {
  border-bottom: white solid 5px;
}

.active {
  color: yellow;
  border-bottom: yellow solid 2px;
}
.active:hover {
  border-bottom: yellow solid 5px;
}
@media only screen and (max-width: 600px) {
  .topbartitle
  {
    display: none;
  }
  .topbar
  {
    justify-content: center;
  }
}