.leaderbox{
    width: 700px;
    height: 75px;
    background-color: #313131;
    border: white solid 3px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
  }
  
  .leaderboxchar{
    height: 75px;
    width: 75px;
    object-fit: cover;
  }
  
  .leaderboxmode {
    font-size: 50px;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    color: white;
    margin: auto;
    margin-right: 10px;
  }
  
  .leaderboxtitle {
    font-size: 40px;
    color: white;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    margin: auto;
    margin-left: 10px;
  }
  
  .leaderboxstat{
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    color: white;
    margin: 0px;
    margin-left: 25px;
  }
  .sl {
    font-size: 25px;
    padding-top: 23px;
  }
  
  .ll {
    font-size: 50px;
    padding-top: 7.5px;
    padding-right: 15px;
    margin-left: 10px;
  }
  .surround
  {
    width: 700px;
    margin: auto;
    margin-top: 15px;
  }
  .leaderboardtitle
  {
    font-size: 60px;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    color: white;
    margin: auto;
  }

  .leaderinactive {
    width: 150px;
    height: 40px;
    font-size: 25px;
    background-color: #333333;
    border: white solid 3px;
    color: white;
    margin-left: 30px;
    cursor: pointer;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    margin-top: 15px;
  }
  .leaderactive {
    width: 150px;
    height: 40px;
    font-size: 25px;
    background-color: #d09100;
    border: white solid 3px;
    color: white;
    margin-left: 30px;
    cursor: pointer;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    margin-top: 15px;
  }
  .navbox
  {
    width: 500px;
    height: 40px;
    background-color: #333333;
    margin: auto;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  .navbutton
  {
    width: 40px;
    height: 40px;
    background-color: black;
    border: white solid 2px;
    color: white;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    cursor: pointer;
    font-size: 30px;
    padding-top: 0px;
  }
  .navtext
  {
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    color: white;
    margin-top: 10px;
  }
  .number
  {
    width: 65px;
  }


  @media only screen and (max-width: 800px) {
    .leaderbox{
      width: 375px;
      height: 60px;
      background-color: #313131;
      border: white solid 3px;
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      margin: auto;
    }
    
    .leaderboxchar{
      height: 60px;
      width: 60px;
      object-fit: cover;
    }
    
    .leaderboxmode {
      font-size: 50px;
      font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
      color: white;
      margin: auto;
      margin-right: 10px;
    }
    
    .leaderboxtitle {
      font-size: 30px;
      color: white;
      font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
      width: 100px;
      margin-left: 10px;
      
    }
    
    .leaderboxstat{
      font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
      color: white;
      margin: 0px;
      margin-left: 25px;
    }
    .sl {
      font-size: 25px;
      padding-top: 23px;
      display: none;
    }
    .number
    {
      width: 30px;
      padding-right: 5px;
    }
    
    .ll {
      font-size: 30px;
      padding-top: 12px;
      padding-right: 5px;
      margin-left: 5px;
    }
    .surround
    {
      width: 100%;
      margin: auto;
      margin-top: 15px;
    }
    .leaderboardtitle
    {
      font-size: 60px;
      font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
      color: white;
      margin: auto;
    }
  
    .leaderinactive {
      width: 150px;
      height: 40px;
      font-size: 25px;
      background-color: #333333;
      border: white solid 3px;
      color: white;
      cursor: pointer;
      font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
      margin-top: 15px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .leaderactive {
      width: 150px;
      height: 40px;
      font-size: 25px;
      background-color: #d09100;
      border: white solid 3px;
      color: white;
      cursor: pointer;
      font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
      margin-top: 15px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .navbox
    {
      width: 300px;
      height: 40px;
      background-color: #333333;
      margin: auto;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
    }
    .navbutton
    {
      width: 40px;
      height: 40px;
      background-color: black;
      border: white solid 2px;
      color: white;
      font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
      cursor: pointer;
      font-size: 30px;
      padding-top: 0px;
    }
    .navtext
    {
      font-size: 20px;
      font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
      color: white;
      margin-top: 10px;
    }
  }