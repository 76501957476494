@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,700&display=swap');


.character {
  width: 175px;
    height: 275px;
    transform: skew(14deg);
    overflow: hidden;
    margin-top: 50px;
    margin-left: 100px;
}

.character img {
  overflow: hidden;
  width: 110%;
  height: 100%;
  padding-left: 8px;
  object-fit: cover;
  transform: skew(-14deg);
}

.title {
  color: white;
  font-size: 80px;
  margin-left: 30px;
  margin-top: 60px;
  text-align: left;
  font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
  margin-bottom: 30px;
}
.subtitle {
  color: white;
  font-size: 40px;
  margin-left: 40px;
  font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
}

.matchbox{
  width: 700px;
  height: 100px;
  background-color: #313131;
  border: white solid 3px;
  display: flex;
  align-items: flex-start;
}

.matchboxchar{
  height: 100%;
  width: auto;
  object-fit: cover;
}

.matchboxmode {
  font-size: 50px;
  font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
  color: white;
  margin: auto;
  margin-right: 10px;
}

.matchboxtitle {
  font-size: 50px;
  font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
  margin: auto;
  margin-left: 10px;
}

.matchboxstat{
  font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
  color: white;
  margin: 0px;
  margin-left: 25px;
}

.s {
  font-size: 25px;
  padding-top: 10px;
}

.l {
  font-size: 50px;
}

.loc {
  font-size: 25px;
  margin: auto;
  color: rgb(167, 167, 167);
}

.body {
  width: 1000px;
  margin: auto;
  margin-top: 20px;
  display: grid;
grid-template-columns: 700px 400px;
grid-template-rows: 1fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
}

.matches {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-area: 1 / 1 / 2 / 2;
}

.stats {
  grid-area: 1 / 2 / 2 / 3;
  width: 100%;
}

.statstack {
  background-color: #313131;
  width: 350px;
  height: 250px;
  margin: 0px 10px;
  margin-bottom: 10px;
  border: black solid 1px;
  border-radius: 10px;
}
.stacktitle {
  font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
  color: white;
  font-size: 50px;
  margin: 0px;
}
.stackstat {
  font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
  color: white;
  font-size: 30px;
  margin: 0px;
}
.topthing
{
  display: flex;
  flex-direction: row;
}
.subbox
{
  display: flex;
  padding-left: "20px";
}
.matchmeta
  {
    display: flex;
    flex-direction: row;
  }
  .matchmetabig {
    display: "flex";
    flex-direction: "column";
     width: 300px;
  }

@media only screen and (max-width: 1000px) {
  .topthing
{
  flex-direction: column;
}
  .character {
    width: 175px;
      height: 275px;
      transform: skew(14deg);
      overflow: hidden;
      margin: auto;
      margin-top: 50px;
  }
  
  .character img {
    overflow: hidden;
    width: 110%;
    height: 100%;
    padding-left: 8px;
    object-fit: cover;
    transform: skew(-14deg);
    z-index: -1;
  }
  
  .title {
    color: white;
    font-size: 60px;
    margin: auto;
    z-index: 25;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    margin-bottom: 10px;
  }
  .subtitle {
    color: white;
    font-size: 40px;
    margin: 0px;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
  }
  .subbox
{
  display: flex;
  flex-direction: column;
  padding-left: "0px";
  margin-top: 20px;
}
  
  .matchbox{
    width: 350px;
    height: 200px;
    background-color: #313131;
    border: white solid 3px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: auto;
  }
  
  .matchboxchar{
    height: 100px;
    width: 100px;
    object-fit: cover;
  }
  
  .matchboxmode {
    font-size: 50px;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    color: white;
    margin: auto;
    margin-right: 10px;
  }
  .matchmeta
  {
    display: flex;
    justify-content: space-between;

  }
  .matchmetabig {
    display: "flex";
    flex-direction: "column";
     width: 250px;
     height: 95px;
     margin-top: 5px;
  }
  .matchboxtitle {
    font-size: 50px;
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
  }
  
  .matchboxstat{
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    color: white;
    margin: 0px;
    width: 116px;
  }
  
  .s {
    font-size: 25px;
    padding-top: 10px;
  }
  
  .l {
    font-size: 50px;
  }
  
  .loc {
    font-size: 20px;
    margin: auto;
    margin-top: 3px;
    color: rgb(167, 167, 167);
    width: 275px;
  }
  
  .body {
    width: 100%;
    margin: auto;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  
  .matches {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-area: 2 / 1 / 3 / 2;
  }
  
  .stats {
    grid-area: 1 / 1 / 2 / 2;
    width: 100%;
  }
  
  .statstack {
    background-color: #313131;
    width: 350px;
    height: 250px;
    margin: 0px auto;
    margin-bottom: 10px;
    border: black solid 1px;
    border-radius: 10px;
  }
  .stacktitle {
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    color: white;
    font-size: 50px;
    margin: 0px;
  }
  .stackstat {
    font-family: 'Roboto Condensed', sans-serif; font-weight:700; font-style:italic;
    color: white;
    font-size: 30px;
    margin: 0px;
  }
}